import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import AboutUs from "../pages/aboutus";
import WhyFune from "../pages/whyfune";
import Inspiration from "../pages/inspiration";
import Advantages from "../pages/advantages";
import Contact from "../pages/contact";
import SecondaryNavbar from "../components/header/secondaryNavbar";
import Header from "../components/header";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header />
            <Home />
          </>
        }
      ></Route>
      <Route
        path="/about-us"
        element={
          <>
            <SecondaryNavbar />
            <AboutUs />
          </>
        }
      />
      <Route
        path="/why-fune"
        element={
          <>
            <SecondaryNavbar />
            <WhyFune />
          </>
        }
      ></Route>
      <Route
        path="/inspiration"
        element={
          <>
            <SecondaryNavbar />
            <Inspiration />
          </>
        }
      />
      <Route
        path="/advantages"
        element={
          <>
            <SecondaryNavbar />
            <Advantages />
          </>
        }
      />
      <Route
        path="/contact"
        element={
          <>
            <SecondaryNavbar />
            <Contact />
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
