import { Col, Container, Row } from "reactstrap";
// import GirlWithPhone from "../../assets/girl_with_phone.png";
import "./functionality.css";
import ChatIcon from "../../assets/Chat.svg";

const IconAndText = () => (
  <div className="d-flex align-items-center gap-3">
    <img src={ChatIcon} alt="" />
    <span>Speechgenerator</span>
  </div>
);

const IconAndTextArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

const FunctionalitySection = () => {
  return (
    <section id="functionality-section" className="functionality-section">
      {/* <img src={GirlWithPhone} alt="" width="100%" className="posit" />s */}
      <Container>
        <Row className="justify-content-end" style={{ padding: "200px 0" }}>
          <Col xs="12" sm="12" md="8" className="d-flex justify-content-center">
            <div className="bg-white bd-radius p-5" style={{ width: "575px" }}>
              <h6
                className="fw-bold text-uppercase mb-3"
                style={{ color: "#70CB94" }}
              >
                Functionaliteiten
              </h6>
              <h3 className="fw-bold mb-4">Deze tools vind je in Fune</h3>
              <div className="d-flex justify-content-between gap-3 flex-wrap">
                {IconAndTextArray.map((value) => (
                  <IconAndText key={value} />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FunctionalitySection;
