import BenefitSection from "../components/benefitSection";
import FunctionalitySection from "../components/functionalitySection";
import FuneAppSection from "../components/funeAppSection";
import HeroSection from "../components/heroSection";
import IntroductionSection from "../components/introSection";
import ReviewSection from "../components/reviewSection";
import TryFunSection from "../components/tryfunsection/TryFunSection";

const Home = () => {
  return (
    <>
      <HeroSection />
      <IntroductionSection />
      <FuneAppSection />
      <FunctionalitySection />
      <ReviewSection />
      <TryFunSection />
      <BenefitSection />
    </>
  );
};

export default Home;
