import { Container, Row } from "reactstrap";
import TestimonialCard from "./testimonialCard";

import TestimonialImage1 from "../../assets/testimonial_img_1.png";
import TestimonialImage2 from "../../assets/testimonial_img_2.png";
import TestimonialImage3 from "../../assets/testimonial_img_3.png";

import "./review.css";

const testimonialData = [
  {
    imgSrc: TestimonialImage1,
    clientName: "Pieter de Jong",
  },
  {
    imgSrc: TestimonialImage2,
    clientName: "Simone Gerritsen",
  },
  {
    imgSrc: TestimonialImage3,
    clientName: "Ali Duran",
  },
];

const ReviewSection = () => {
  return (
    <section id="review-section" className="review-section">
      <Container className="text-center">
        <h6
          className="fw-bold text-uppercase mb-2"
          style={{ color: "#CE7EBF" }}
        >
          Reviews
        </h6>
        <h2 className="fw-bold mb-5">Wat anderen vinden van Fune</h2>
        <Row>
          {testimonialData?.map((testimonial, index) => (
            <TestimonialCard
              key={`Testimonial ${index}`}
              imgSrc={testimonial.imgSrc}
              clientName={testimonial.clientName}
            />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ReviewSection;
