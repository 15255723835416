import { Col, Container, Row } from "reactstrap";
import WorldMap from "../../assets/world-map.png";

const AmibitionSection = () => {
  return (
    <section
      id="amibition-section"
      className="ambition-section mt-5 overflow-hidden"
    >
      <Container>
        <Row>
          <Col xs={12} sm={5} className="pt-5">
            <h6
              className="text-uppercase fw-bold my-3"
              style={{ color: "#CE7EBF" }}
            >
              Ambities
            </h6>
            <h1 className="fw-bold mb-4">
              Fune wereldwijd <br />
              uitrollen
            </h1>
            <p>
            FUNE droomt groot: uitvaartplanning wereldwijd vernieuwen. We willen overal hulp en gemak bieden bij het organiseren van een persoonlijk afscheid, met aandacht voor culturele tradities. Ons doel? Een platform dat iedereen, waar ook ter wereld, helpt een waardig en persoonlijk afscheid te regelen. We maken onze diensten wereldwijd beschikbaar om de manier waarop mensen afscheid nemen te veranderen, met alle respect voor lokale gewoonten. Met FUNE wordt elk afscheid een herinnering vol liefde, voor iedereen, overal.
            </p>
          </Col>
          <Col xs={12} sm={7}>
            <img
              src={WorldMap}
              alt=""
              height={750}
              style={{ overflowX: "hidden" }}
              // className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AmibitionSection;
