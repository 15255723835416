import React from "react";
import "./TryFunSection.css";
import a1 from "../../assets/shutterstock.png";

const TryFunSection = () => {
  return (
    <div className="try-fun-section-wrapper d-lg-block d-none">
      <div className="container">
        <div className="row">
          <div className="col-6 text-side">
            <h3 className="mb-3">Fune proberen</h3>
            <h2 className="mb-4">Maak binnen 1 minuut een account aan</h2>
            <p>Start vandaag nog met het voorbereiden van een respectvol en persoonlijk afscheid met FUNE. Binnen een minuut heb je een account en krijg je gelijk toegang tot alles wat nodig is voor een uitvaart die helemaal past bij jou of je dierbare. FUNE maakt het proces simpel en zorgt dat elk detail van het afscheid met respect en volgens jouw wensen wordt ingevuld. Maak nu je account aan en ga met een gerust hart verder, wetende dat alles geregeld is.</p>
          </div>
          <div className="col-6 form-side">
            <img src={a1} alt="" />

            <div className="d-flex justify-content-end">
              <div className="form-wrapper">
                <form action="">
                  <h2 className="mb-3">Vul hier je gegevens in</h2>

                  <div className="mb-3">
                    <label className="form-label">Voornaam</label>
                    <input type="text" className="form-control" placeholder="John" />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Achternaam</label>
                    <input type="text" className="form-control" placeholder="Doe" />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Email address</label>
                    <input type="email" className="form-control" placeholder="Jane@google.nl" />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Telefoon</label>
                    <input type="text" className="form-control" placeholder="+31 06 12345678" />
                  </div>

                  <div className="d-flex justify-content-end">
                    <button className="btn submit-btn">Aanmelden</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryFunSection;
