import { Button, Col, Container, Row } from "reactstrap";
import BenefitSection from "../components/benefitSection";
import AboutUsHeroImage from "../assets/aboutus-hero.png";
import AmibitionSection from "../components/amibitionSection";

const AboutUs = () => {
  return (
    <>
      <section
        id="aboutus-hero"
        className="aboutus-hero"
        style={{ paddingTop: "100px" }}
      >
        <Container>
          <Row className="pb-5 justify-content-between">
            <Col xs="12" md="5">
              <h6
                className="text-uppercase fw-bold mt-5 mb-3"
                style={{ color: "#CE7EBF" }}
              >
                eigen ervaring
              </h6>
              <h1 className="fw-bold mb-4">
                Een uitvaart regelen is enorm lastig
              </h1>
              <p>
                Bij FUNE snappen we hoe lastig het kan zijn om een uitvaart te
                regelen, vooral in een tijd vol emoties waarin je besluiten moet
                nemen die een heel leven eer aan doen. Daarom bestaat FUNE: we
                maken het plannen van een persoonlijke uitvaart toegankelijker,
                zodat je je kunt richten op wat echt telt. Met FUNE wordt elke
                stap helder en persoonlijk, met ondersteunende tools en diensten
                om het jou gemakkelijker te maken. We staan voor je klaar in
                deze moeilijke periode, helpen je een waardig afscheid te
                regelen dat perfect past bij de unieke persoon die jij of je
                dierbare is.
              </p>
            </Col>
            <Col xs="12" md="7">
              <img
                src={AboutUsHeroImage}
                alt=""
                className="img-fluid"
                style={{ marginLeft: "auto", display: "block" }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="idea">
        <Container className="text-white h-100">
          <Row className="h-100 justify-content-start justify-content-sm-end">
            <Col xs="12" sm="5" className="my-auto">
              <h2 className="fw-bold mb-5">
                Hoe het idee <br /> is ontstaan
              </h2>
              <p>
                Het idee voor FUNE ontstond uit een persoonlijke ervaring met
                het verlies van een dierbare. In die zware tijd zagen we hoe
                ingewikkeld en emotioneel uitvaartplanning kan zijn. Dat moest
                anders kunnen, dachten we. Zo kwam FUNE tot leven: een plek waar
                je lastige beslissingen helder en van tevoren kunt nemen. Het
                idee? Een rustig een afscheid plannen dat echt past bij het
                leven en de laatste wensen van de overledene. Met een mix van
                technologie en empathie wil FUNE uitvaartplanning veranderen in
                iets wat minder zwaar aanvoelt en vooral heel betekenisvol en
                persoonlijk wordt.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <AmibitionSection />
      <section id="partners-section" className="partners-section mt-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="6" className="text-center">
              <h1 className="fw-bold mb-4">Zakelijke partners</h1>
              <p className="mb-4">
                Bij FUNE geloven we sterk in de kracht van samenwerken. We zijn
                op zoek naar zakelijke partners, zoals bloemisten,
                uitvaartcentra, muzikanten, en cateraars, die onze missie
                ondersteunen om persoonlijke uitvaarten te verrijken. Door met
                ons samen te werken, krijg je toegang tot een grote groep
                klanten die waarde hecht aan kwaliteit en diepgang in hun
                afscheid. Zie dit als jouw kans om samen met ons nieuwe
                mogelijkheden te ontdekken en te zorgen voor een waardevol en
                persoonlijk afscheid voor iedereen.
              </p>
              {/* <Button className="mybtn">Mail ons</Button> */}
            </Col>
          </Row>
        </Container>
      </section>
      <BenefitSection />
    </>
  );
};

export default AboutUs;
