import { Button, Col, Container, Row } from "reactstrap";
import AmibitionSection from "../components/amibitionSection";
import BenefitSection from "../components/benefitSection";
import AboutUsHeroImage from "../assets/why-fune.png";
import FunePhone from "../assets/fune_phone.png";
import FunePhoneMobile from "../assets/fune-phone-mobile.png";

const WhyFune = () => {
  return (
    <>
      <section
        id="aboutus-hero"
        className="aboutus-hero"
        style={{ paddingTop: "100px" }}
      >
        <Container>
          <Row className="pb-5 justify-content-between">
            <Col xs="12" md="5">
              <h6
                className="text-uppercase fw-bold mt-5 mb-3"
                style={{ color: "#CE7EBF" }}
              >
                Wat als...
              </h6>
              <h1 className="fw-bold mb-4">
                Nabestaande hebben het al moeilijk genoeg
              </h1>
              <p>
              Het verlies van een dierbare is ontzettend zwaar. FUNE wil de stress rond het regelen van een uitvaart wegnemen met een proces dat eenvoudig en respectvol is. We zorgen ervoor dat nabestaanden zich kunnen focussen op het rouwen en het koesteren van de laatste momenten, zonder de last van organisatorische zorgen. Met FUNE wordt het voorbereiden van een afscheid een moment van herinneren en eerbetoon.
              </p>
            </Col>
            <Col xs="12" md="6">
              <img
                src={AboutUsHeroImage}
                alt=""
                className="img-fluid"
                style={{ maxWidth: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="partners-section" className="partners-section my-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="6" className="text-center">
              <h1 className="fw-bold mb-4">Van inspiratie tot speech</h1>
              <p className="mb-4">
              De juiste woorden vinden bij een afscheid is soms best lastig. Met FUNE wordt het makkelijker om inspiratie om te zetten in een speech die recht doet aan het leven en de nalatenschap van jou of je dierbare. Ons platform helpt je met tools en tips om toespraken te schrijven die echt binnenkomen: van persoonlijke verhalen tot de kern van iemands leven. FUNE staat bij elke stap naast je.
              </p>
              <button className="download-button" type="submit">
                Meer inspiratie
              </button>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="functionality-section" className="vooraf-section">
        {/* <img src={GirlWithPhone} alt="" width="100%" className="posit" />s */}
        <Container>
          <Row className="justify-content-end" style={{ padding: "200px 0" }}>
            <Col xs="12" sm="5" className="my-auto">
              <h2 className="fw-bold mb-5">
                Geef nabestaande <br />
                vooraf toegang
              </h2>
              <p className="mb-5">
              Door nabestaanden vooraf toegang te geven tot uitvaartwensen via FUNE, wordt hun last in rouwtijd verlicht. FUNE stelt je in staat niet alleen de details van je uitvaart, zoals muziek en locatie, vast te leggen, maar biedt ook inzicht in belangrijke zaken zoals wachtwoorden, juridische documenten en bezittingen. Zo weten je dierbaren precies hoe jij je afscheid en nalatenschap geregeld wilt hebben, waardoor jullie je kunnen focussen op herdenken en samenzijn, met de geruststelling dat alles naar jouw wensen is voorbereid.
              </p>
              <button className="vooraf-button" type="submit">
                Maak&nbsp;een&nbsp;account&nbsp;aan
              </button>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        id="fune-app-section"
        className="fune-app-section"
        style={{ paddingTop: "100px" }}
      >
        <Container>
          <Row className="gy-5">
            <Col xs="12" md="4" className="text-start pt-5">
              <h6 className="text-uppercase my-3" style={{ color: "#CE7EBF" }}>
                Resultaat
              </h6>
              <h2 className="fw-bold mb-3">
                Alles om er zeker van te zijn dat het goed zit.
              </h2>
              <p className="mb-4">
              Een uitvaart die volledig voldoet aan jouw wensen? Dat regel je moeiteloos met FUNE. Ons platform heeft alles wat je nodig hebt om ervoor te zorgen dat elk detail van het afscheid klopt, van ceremonie tot de afwikkeling van de laatste zaken. Dit betekent niet alleen een persoonlijk en respectvol eerbetoon, maar ook rust voor je nabestaanden. Met FUNE is alles geregeld, zodat jij en je dierbaren met een gerust hart verder kunnen.
              </p>
              <Button color="primary" className="rounded-pill">
                Download app
              </Button>
            </Col>
            <Col xs="12" md="8" className="overflow-hidden text-center">
              <img
                src={FunePhone}
                alt=""
                className="img-fluid d-none d-sm-inline"
              />
              <img
                src={FunePhoneMobile}
                alt=""
                className="img-fluid d-inline d-sm-none"
                style={{ maxWidth: "400px" }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WhyFune;
