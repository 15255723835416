import { Button, Container } from "reactstrap";
import Facebook from "../../assets/facebook.svg";
import Twitter from "../../assets/twitter.svg";
import Instagram from "../../assets/instagram.svg";
import FuneLogoShort from "../../assets/logo_short.png";
import FuneLogoWhite from "../../assets/fune_logo_white.svg";

const Footer = () => {
  return (
    <>
      <section id="cta-section" className="cta-section">
        <Container className="d-flex flex-column gap-5 align-items-center">
          <img src={FuneLogoShort} width={110} height={110} alt="" />
          <div className="d-flex flex-column gap-3 align-items-center">
            <span className="text-white">Probeer nu gratis</span>
            <img src={FuneLogoWhite} width={170} height={40} alt="" />
          </div>
          <div className="d-flex gap-2">
            <Button color="primary" className="custom-button">
              Android
            </Button>
            <Button color="primary" className="custom-button">
              IOS
            </Button>
          </div>
        </Container>
      </section>
      <footer id="footer" className="footer">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 gap-md-5 flex-column flex-md-row">
              <span>©FUNE 2024</span>
              <span>Voorwaarden</span>
              <span>Privacy Statement</span>
            </div>
            <div className="d-flex gap-1 gap-md-3">
              <span className="social-icon">
                <img src={Facebook} alt="" className="facebook-icon" />
              </span>
              <span className="social-icon">
                <img src={Twitter} alt="" className="twitter-icon" />
              </span>
              <span className="social-icon">
                <img src={Instagram} alt="" className="instagram-icon" />
              </span>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
