import { Button, Col, Container, Row } from "reactstrap";
import FunePhone from "../../assets/fune_phone.png";
import "./funeapp.css";

const FuneAppSection = () => {
  return (
    <section id="fune-app-section" className="fune-app-section">
      <Container>
        <Row>
          <Col xs="12" md="4" className="text-start pt-5">
            <h6 className="text-uppercase my-3" style={{ color: "#CE7EBF" }}>
              Een unieke app
            </h6>
            <h2 className="fw-bold mb-3">
              Fune maakt
              <br />
              het mogelijk
            </h2>
            <p className="mb-4">
              FUNE maakt het regelen van een uitvaart eenvoudig en persoonlijk.
              Met gebruiksvriendelijke functies kies je moeiteloos muziek uit,
              selecteer je bloemen en leg je een boodschap vast voor je
              dierbaren, precies zoals jij of een geliefde het wil. Het platform
              geeft ook helderheid over de kosten, laat je herinneringen
              digitaal vastleggen en maakt het makkelijk om informatie te delen
              met iedereen die betrokken is.
            </p>
            <Button color="primary" className="rounded-pill">
              Waarom Fune
            </Button>
          </Col>
          <Col xs="12" md="8">
            <img src={FunePhone} alt="" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FuneAppSection;
