import { Container, Row } from "reactstrap";
import BenefitCard from "./benefitCard";

import BenefitCardImage1 from "../../assets/benefit_card-1.png";
import BenefitCardImage2 from "../../assets/benefit_card-2.png";
import BenefitCardImage3 from "../../assets/benefit_card-3.png";

const benefitCardData = [
  {
    imgSrc: BenefitCardImage1,
    description: "Bewaar veilig al je gegevens en wensen in één omgeving.",
  },
  {
    imgSrc: BenefitCardImage2,
    description: "Met Fune belast je je nabestaande niet met veel uitzoekwerk.",
  },
  {
    imgSrc: BenefitCardImage3,
    description: "Vertrouwenspersonen kunnen bij je gegevens wanneer nodig.",
  },
];

const BenefitSection = () => {
  return (
    <section id="benefit-section" className="benefit-section mt-5">
      <Container>
        <h2 className="fw-bold mb-5 text-center">Top 3 voordelen van Fune</h2>
        <Row>
          {benefitCardData.map((benefitcard, index) => (
            <BenefitCard
              imgSrc={benefitcard.imgSrc}
              description={benefitcard.description}
            />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BenefitSection;
