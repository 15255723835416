import React from "react";
import "./App.css";
import AppRoutes from "./routes";
import ContactSection from "./components/contactSection";
import Footer from "./components/footer";

const App: React.FC = () => {
  return (
    <>
      <AppRoutes />
      <ContactSection />
      <Footer />
    </>
  );
};

export default App;
