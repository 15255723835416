import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";

const ContactSection = () => {
  return (
    <section id="contact-section" className="contact-section">
      <Container>
        <div className="contact-form">
          <h2 className="fw-bold text-center">Contact</h2>
          <p className="my-4 text-center">
            Benieuwd hoe FUNE je kan helpen met het voorbereiden van een
            persoonlijk afscheid? Vul dan het contactformulier hieronder in. We
            staan klaar om al je vragen te beantwoorden en je alle info te geven
            die je nodig hebt. We ondersteunen je graag bij elke stap, want wij
            weten als geen ander dan een waardig afscheid belangrijk is, niet
            alleen voor jou, maar ook voor je nabestaanden.
          </p>
          <Form>
            <FormGroup>
              <Label for="firstName" className="fw-semibold text-blue">
                Voornaam
              </Label>
              <Input id="firstName" name="firstName" type="text" />
            </FormGroup>
            <FormGroup>
              <Label for="lastName" className="fw-semibold text-blue">
                Achternaam
              </Label>
              <Input id="lastName" name="lastName" type="text" />
            </FormGroup>
            <FormGroup>
              <Label for="email" className="fw-semibold text-blue">
                E-mail
              </Label>
              <Input id="email" name="email" type="email" />
            </FormGroup>
            <FormGroup>
              <Label for="telephone" className="fw-semibold text-blue">
                Telefoon
              </Label>
              <Input id="telephone" name="telephone" type="text" />
            </FormGroup>
            <div className="text-center mt-5">
              <Button color="primary" className="custom-button">
                Neem contact met mij op
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default ContactSection;
