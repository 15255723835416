import { Button, Col, Container, Row } from "reactstrap";
import FunePhone from "../assets/fune_phone.png";
import FunePhoneMobile from "../assets/fune-phone-mobile.png";
import FunctionalitySection from "../components/functionalitySection";
import BenefitCard from "../components/benefitSection/benefitCard";

import BenefitCardImage1 from "../assets/benefit_card-1.png";
import BenefitCardImage2 from "../assets/benefit_card-2.png";
import BenefitCardImage3 from "../assets/benefit_card-3.png";


const benefitCardData = [
  {
    imgSrc: BenefitCardImage1,
    description: "Bewaar veilig al je gegevens en wensen in één omgeving.",
  },
  {
    imgSrc: BenefitCardImage2,
    description: "Met Fune belast je je nabestaande niet met veel uitzoekwerk.",
  },
  {
    imgSrc: BenefitCardImage3,
    description: "Creëer een blijvende, persoonlijke herinnering naar jouw wens.",
  },
  {
    imgSrc: BenefitCardImage2,
    description: "Detailleer je afscheid, van muziek tot bloemen.",
  },
  {
    imgSrc: BenefitCardImage3,
    description: "Registreer bezittingen en wensen voor duidelijke nalatenschap.",
  },
  {
    imgSrc: BenefitCardImage1,
    description: "Beperk financiële lasten met heldere kosten.",
  },
  {
    imgSrc: BenefitCardImage2,
    description: "Laat persoonlijke spraak of video boodschappen achter.",
  },
  {
    imgSrc: BenefitCardImage3,
    description: "Vind ondersteuning in onze tips en blogs.",
  },
  {
    imgSrc: BenefitCardImage1,
    description: "Vul jouw bucketlist in en doe wat je altijd nog had willen doen.",
  },
];

const Advantages = () => {
  return (
    <>
      <section
        id="fune-app-section"
        className="fune-app-section"
        style={{ paddingTop: "100px" }}
      >
        <Container>
          <Row className="gy-5">
            <Col xs="12" md="4" className="text-start pt-5">
              <h6 className="text-uppercase my-3" style={{ color: "#CE7EBF" }}>
                Loslaten
              </h6>
              <h2 className="fw-bold mb-3">
                Met een gerust <br />
                hart alles <br />
                achterlaten
              </h2>
              <p className="mb-4">
              Loslaten is een natuurlijk deel van het leven, maar kan vragen oproepen over de achtergelaten zaken. Met FUNE regel je met een gerust hart jouw afscheid: van uitvaartwensen tot het beheer van je digitale erfenis, zoals wachtwoorden en belangrijke documenten. Met FUNE laat je los in vrede, met de zekerheid dat alles goed verzorgd is.
              </p>
              <Button color="primary" className="rounded-pill">
                Waarom Fune
              </Button>
            </Col>
            <Col xs="12" md="8" className="overflow-hidden text-center">
              <img
                src={FunePhone}
                alt=""
                className="img-fluid d-none d-sm-inline"
              />
              <img
                src={FunePhoneMobile}
                alt=""
                className="img-fluid d-inline d-sm-none"
                style={{ maxWidth: "400px" }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <FunctionalitySection />
      <section
        id="benefit-section"
        className="benefit-section"
        style={{ marginTop: "100px" }}
      >
        <Container>
          <h2 className="fw-bold mb-5 text-center">Top 3 voordelen van Fune</h2>
          <Row className="row-gap-4">
            {benefitCardData.map((benefitcard, index) => (
              <BenefitCard
                imgSrc={benefitcard.imgSrc}
                description={benefitcard.description}
              />
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Advantages;
