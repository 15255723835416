import { Button, Col, Container, Row } from "reactstrap";
import "./introSection.css";
const IntroductionSection = () => {
  return (
    <section id="intro-section" className="intro-section">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="6" className="text-center">
            <h1 className="fw-bold mb-4">Introductie van Fune</h1>
            <p className="mb-4">
              Iedereen komt een keer te overlijden. Hoewel het voor vele van ons
              nog ver weg zal zijn, is het wel fijn wanneer dit goed geregeld
              is. Met Fune creëer je overzicht met al je wensen rondom jouw
              uitvaart eenvoudig en alle informatie die van belang zijn voor
              jouw nabestaande . Van inspiratie, speechgenerator, Recordings,
              bucketlist tot aan het overzicht van al je bezittingen… alles goed
              geregeld.
            </p>
            <Button color="primary" className="rounded-pill ">
              Meer over Fune
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IntroductionSection;
