import React from "react";
import { Card, CardBody, CardText, Col } from "reactstrap";

interface BenefitCardProps {
  imgSrc: string;
  description: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ imgSrc, description }) => {
  return (
    <Col xs="12" sm="6" lg="4" className="d-flex justify-content-center">
      <Card
        style={{
          width: "18rem",
        }}
        className="border-0"
      >
        <div className="text-center">
          <img alt="Sample" src={imgSrc} />
        </div>
        <CardBody>
          <CardText className="fw-bold text-center">{description}</CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BenefitCard;
