import React from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
} from "reactstrap";
import Rating from "./rating";

interface TestimonialCardProps {
  imgSrc: string;
  clientName: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  imgSrc,
  clientName,
}) => {
  return (
    <Col className="d-flex justify-content-center">
      <Card
        style={{
          width: "18rem",
        }}
        className="border-0"
      >
        <div className="text-center">
          <img alt="Sample" src={imgSrc} width={141} height={159} />
        </div>
        <CardBody>
          <CardTitle tag="h5" className="my-4">
            {clientName}
          </CardTitle>
          <CardSubtitle className="mb-3" tag="h6">
            <Rating />
          </CardSubtitle>
          <CardText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris.
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TestimonialCard;
