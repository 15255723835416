import React, { useState } from "react";
import FUNELogoWhite from "../../assets/fune_logo_white.svg";
import FUNELogoColor from "../../assets/fune-logo-color.svg";
import { Link } from "react-router-dom";
import "./header.css";
import { IoIosArrowDown } from "react-icons/io";

const Header: React.FC = () => {
  const [navLinksColor, setNavLinksColor] = useState("light-link");
  const [navBackground, setNavBackground] = useState("");
  const [langaugeBar, setLangaugeBar] = useState("");
  const [navMargin, setNavMargin] = useState("");

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavLinksColor("dark-link");
      setNavBackground("nav-background");
      setLangaugeBar("language-visibility");
      setNavMargin("nav-margin");
    } else {
      setNavLinksColor("light-link");
      setNavBackground("");
      setLangaugeBar("");
      setNavMargin("");
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <>
      <div
        className={`container fixed-top d-flex justify-content-end gap-3 mt-2 ${langaugeBar}`}
      >
        <span className="loginLanguage">Inloggen</span>
        <span className="loginLanguage">
          NL <IoIosArrowDown />
        </span>
      </div>
      <nav
        className={`navbar navbar-expand-lg fixed-top mt-4 ${navBackground} ${navMargin}`}
      >
        <div className="container-md">
          <Link className="navbar-brand" to="/">
            <img
              width="100px"
              src={window.scrollY > 0 ? FUNELogoColor : FUNELogoWhite}
              alt=""
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${navLinksColor}`}
                  aria-current="page"
                  to="/about-us"
                >
                  Over ons
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${navLinksColor}`} to="/why-fune">
                  Waarom Fune
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${navLinksColor}`} to="/advantages">
                  Voordelen
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${navLinksColor}`} to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <button className="download-button" type="submit">
              Download Fune
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
