import { Col, Container, Row } from "reactstrap";
import ContactPageImage from "../assets/contact.png";
import RotterdamBuilding from "../assets/Rotterdam_Building-plotvis 2 (1).png";

const Contact = () => {
  return (
    <section id="contact" className="contact" style={{ paddingTop: "100px" }}>
      <Container>
        <Row className="pb-5 justify-content-between">
          <Col xs={12} sm={12} md={6}>
            <h6
              className="text-uppercase fw-bold my-3"
              style={{ color: "#CE7EBF" }}
            >
              Contact
            </h6>
            <h1 className="fw-bold mb-4">
              Vragen of <br />
              interesse in <br />
              investeren?
            </h1>
            <p>
            Heb je vragen over FUNE of ben je geïnteresseerd in investeringsmogelijkheden? We horen graag van je! Ons team staat klaar om je vragen te beantwoorden en meer te vertellen over hoe we samen de uitvaartwereld kunnen veranderen. Met elkaar zorgen we voor waardige en persoonlijke afscheidsmomenten.
            </p>
            <h3 className="fw-bold">+31 010 - 12345678</h3>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <img
              src={ContactPageImage}
              alt=""
              className="img-fluid custom-image"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="d-flex flex-wrap gap-5">
          <div className="order-1 order-sm-0 fune-address">
            <img src={RotterdamBuilding} alt="" />
          </div>
          <div className="order-0 order-sm-1 d-flex flex-column align-items-start gap-2 text-start">
            <h4 className="fw-bold">Fune B.V.</h4>
            <span>Straatnaam 123</span>
            <span>4567 FU Rotterdam</span>
            <span>BTW 1234567890</span>
            <span>Bank . 1234567890</span>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
