import FillStar from "../../assets/Star.svg";
import OutlineStart from "../../assets/OutlineStar.svg";

const Rating = () => {
  return (
    <div>
      <img src={FillStar} alt="" />
      <img src={FillStar} alt="" />
      <img src={FillStar} alt="" />
      <img src={FillStar} alt="" />
      <img src={OutlineStart} alt="" />
    </div>
  );
};

export default Rating;
