import { Button, Col, Container, Row } from "reactstrap";
import HeroImage from "../../assets/hero_section_image.png";
import "./hero.css";

const HeroSection = () => {
  return (
    <>
      <section
        id="hero-section"
        className="hero-section text-white position-relative"
      >
        <Container>
          <Row className="pb-5">
            <Col md="6" sm="12" className="text-start my-auto">
              <h1 className="fw-bold mb-4" style={{ fontSize: "52px" }}>
                Zeker weten
                <br />
                dat je alles
                <br />
                goed is geregeld
              </h1>
              <p className="mb-4">
                Met de Fune app creëer je overzicht van alles dat belangrijk
                voor je uitvaart. Creëer rust voor je nabestaande en rust in je
                hoofd omdat je met Fune alles netjes achterlaat wanneer het
                zover is.
              </p>
              <Button color="primary" className="rounded-pill">
                Download de app
              </Button>
            </Col>
            <Col md="6" sm="12">
              <img src={HeroImage} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
        {/* <div className="blue-cover-circle"></div> */}
      </section>
    </>
  );
};

export default HeroSection;
